<script setup lang="ts">
import { cn } from '@laam/lib/utils';
import type { HTMLAttributes } from 'vue';

const props = defineProps<{ class?: HTMLAttributes['class'] }>();
</script>

<template>
	<div
		:class="
			cn(
				'flex flex-col-reverse sm:flex-row sm:justify-end sm:gap-x-md',
				props.class,
			)
		"
	>
		<slot />
	</div>
</template>
